@import url("https://fonts.googleapis.com/css?family=PT+Sans");
.footer-link, .visible-mobile, .mobile-banner {
  display: none;
}

html {
  height: 100%;
}

.mobileMenu {
  background: #7e4509;
  padding: 16px 20px 16px;
  margin: 0 -15px 14px;
  position: relative;
}

.mobileMenu a {
  text-decoration: none;
  color: #ffffff;
  font-size: 22px;
  font-family: 'PT Sans', sans-serif;
}

.mobileMenu .icon-menu {
  margin-top: -5px;
  margin-right: 14px;
}

.mobileMenu .icon-search {
  float: right;
  margin-right: 5px;
}

.mobileMenu .icon-search.active {
  background-image: url(../images/close.svg);
}

.mobileMenu .icon-search.active + .search {
  display: block;
}

@media (min-width: 992px) {
  .hidden-lg {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .responsive {
    background: #fff1d5;
    min-height: 100%;
    height: auto;
    position: relative;
    min-width: 320px;
  }
  .responsive .footer_placeholder {
    height: 86px;
  }
  .responsive #podlozka {
    height: auto;
  }
  .responsive.gradient-bg {
    background: radial-gradient(circle at 100% 100%, #fff1bf, #c7833b 56%, #8a480f);
  }
  .responsive.gradient-bg.home #maincontent {
    background: transparent;
    padding: 20px 15px;
  }
  .responsive.gradient-bg.home #maincontent > * {
    display: none;
  }
  .responsive.gradient-bg.home #maincontent .mobileLinks {
    display: block;
  }
  .responsive.is-home {
    background: #000 url("../images/body_bg.jpg") center top no-repeat;
    background-size: cover;
    font-family: 'PT Sans', sans-serif;
  }
  .responsive.is-home #maincontent {
    background: transparent;
    padding: 20px 15px;
  }
  .responsive.is-home #maincontent > * {
    display: none;
  }
  .responsive.is-home #maincontent .mobileLinks {
    display: block;
  }
  .responsive * {
    box-sizing: border-box;
  }
  .responsive .visible-mobile {
    display: block !important;
  }
  .responsive .hidden-mobile {
    display: none !important;
  }
  .responsive #wrapper,
  .responsive #header,
  .responsive #maincontent_bottom_block,
  .responsive .center_subcol.wide,
  .responsive #maincontent,
  .responsive .right.wide,
  .responsive .moduletable_activitemodule,
  .responsive #footer,
  .responsive .left {
    width: 100%;
    float: none;
    margin: 0;
  }
  .responsive .catalog_title, .responsive .distilleriesDescription, .responsive .table-drinks, .responsive #SearchForm, .responsive .admin_text {
    width: 100%;
  }
  .responsive #maincontent {
    padding: 0 15px;
    background-color: transparent;
  }
  .responsive #header {
    background: #000000;
    height: auto;
    padding: 23px 0 24px;
    font-family: 'PT Sans', sans-serif;
  }
  .responsive #header #logo {
    float: none;
    width: 100%;
    margin: 0;
    text-align: center;
  }
  .responsive .moduletable_top_menu, .responsive .moduletable_breadcrumbs {
    display: none;
  }
  .responsive .right_subcol, .responsive .analyticstracking {
    position: absolute;
    left: -9999em;
    top: -9999em;
  }
  .responsive .right {
    background-image: none;
    float: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .responsive .moduletable_activitemodule .col {
    width: 100% !important;
    display: block;
  }
  .responsive #footer {
    font-family: 'PT Sans', sans-serif;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .responsive #footer > * {
    display: none;
  }
  .responsive #footer .moduletable_footer_copyright {
    font-family: 'PT Sans', sans-serif;
    background: #000000;
    padding: 13px 0;
    font-size: 14px;
    color: #ebebeb;
    float: none;
    display: block;
    text-align: center;
    margin: 0;
  }
  .responsive #footer .footer-link {
    text-align: center;
    display: block;
    font-size: 16px;
    color: #000000;
    margin-bottom: 14px;
    text-decoration: none;
  }
  .responsive #footer .footer-link:hover {
    text-decoration: none;
  }
  .responsive #footer .footer-link span {
    display: inline-block;
    line-height: 21px;
    border-bottom: 1px dashed;
  }
  .responsive .icon {
    display: inline-block;
    vertical-align: middle;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .responsive .icon-user {
    width: 31px;
    height: 35px;
    background-image: url(../images/icon_user.svg);
  }
  .responsive .icon-cat {
    width: 31px;
    height: 35px;
    background-image: url(../images/icon_cat.svg);
  }
  .responsive .icon-forum {
    width: 31px;
    height: 35px;
    background-image: url(../images/icon_forum.svg);
  }
  .responsive .icon-cont {
    width: 31px;
    height: 35px;
    background-image: url(../images/icon_cont.svg);
  }
  .responsive .icon-menu {
    width: 26px;
    height: 21px;
    background-image: url(../images/icon_menu.svg);
  }
  .responsive .icon-search {
    width: 25px;
    height: 25px;
    background-image: url(../images/icon_search.svg);
    background-size: 100% 100%;
  }
  .responsive .icon-key {
    width: 26px;
    height: 26px;
    background-image: url(../images/icon-key.svg);
  }
  .responsive .btn, .responsive .rsform-submit-button, .responsive .login button[type="submit"], .responsive .bottle-add-container .bottle-add-step .fileContainer, .responsive .bottle-add-container .bottle-add-step.step-4 .form-actions button[type="submit"] {
    display: inline-block;
    vertical-align: middle;
    border-radius: 10px;
    background-color: #000000;
    border: solid 2px #ffffff;
    font-size: 22px;
    text-align: center;
    color: #ffffff;
    padding: 14px 18px;
    text-decoration: none;
    font-weight: 400;
    position: relative;
    max-width: 100%;
  }
  .responsive .btn-block, .responsive .rsform-submit-button, .responsive .login button[type="submit"], .responsive .bottle-add-container .bottle-add-step .fileContainer, .responsive .bottle-add-container .bottle-add-step.step-4 .form-actions button[type="submit"] {
    display: block;
    width: 100%;
  }
  .responsive .btn-trans {
    background: -moz-linear-gradient(left, rgba(180, 128, 66, 0.76) 0%, rgba(180, 128, 66, 0.76) 100%);
    background: -webkit-linear-gradient(left, rgba(180, 128, 66, 0.76) 0%, rgba(180, 128, 66, 0.76) 100%);
    background: linear-gradient(to right, rgba(180, 128, 66, 0.76) 0%, rgba(180, 128, 66, 0.76) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c2b48042', endColorstr='#c2b48042', GradientType=1);
    border: solid 2px #ffffff;
  }
  .responsive .btn-red {
    background-image: linear-gradient(to left, #bb3b3b, #d96249);
    font-family: 'PT Sans', serif;
  }
  .responsive .btn-icon {
    text-align: left;
    padding-left: 60px;
  }
  .responsive .btn-icon .icon {
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
  }
  .responsive .mobileLinks {
    margin-bottom: 11px;
  }
  .responsive .mobileLinks .btn, .responsive .mobileLinks .rsform-submit-button, .responsive .mobileLinks .login button[type="submit"], .responsive .login .mobileLinks button[type="submit"], .responsive .mobileLinks .bottle-add-container .bottle-add-step .fileContainer, .responsive .bottle-add-container .bottle-add-step .mobileLinks .fileContainer, .responsive .mobileLinks .bottle-add-container .bottle-add-step.step-4 .form-actions button[type="submit"], .responsive .bottle-add-container .bottle-add-step.step-4 .form-actions .mobileLinks button[type="submit"] {
    margin-bottom: 30px;
  }
  .responsive .mobileLinks .btn:last-child, .responsive .mobileLinks .rsform-submit-button:last-child, .responsive .mobileLinks .login button[type="submit"]:last-child, .responsive .login .mobileLinks button[type="submit"]:last-child, .responsive .mobileLinks .bottle-add-container .bottle-add-step .fileContainer:last-child, .responsive .bottle-add-container .bottle-add-step .mobileLinks .fileContainer:last-child, .responsive .mobileLinks .bottle-add-container .bottle-add-step.step-4 .form-actions button[type="submit"]:last-child, .responsive .bottle-add-container .bottle-add-step.step-4 .form-actions .mobileLinks button[type="submit"]:last-child {
    margin-bottom: 0;
  }
  .responsive .item-page {
    font-family: 'PT Serif', serif;
    margin-bottom: 32px;
  }
  .responsive .item-page h1 {
    text-align: center;
    margin-bottom: 13px;
    font-size: 24px;
  }
  .responsive .item-page h4 {
    margin-top: 0;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 10px;
  }
  .responsive .item-page p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
  .responsive .componentheading {
    text-align: center;
    padding-top: 20px;
    font-size: 24px;
    margin-bottom: 12px;
  }
  .responsive #userForm table, .responsive #userForm table tbody {
    width: 100%;
    display: block;
  }
  .responsive #userForm table tr {
    display: block;
    margin-bottom: 14px;
  }
  .responsive #userForm table tr td {
    display: block;
    padding: 0;
    font-size: 14px;
    font-style: italic;
    position: relative;
  }
  .responsive #userForm table tr td:first-child {
    padding-bottom: 5px;
  }
  .responsive .login fieldset #password,
  .responsive .login fieldset #username,
  .responsive .rsform-input-box, .responsive .rsform-select-box, .responsive .rsform-captcha-box, .responsive .rsform-text-box {
    height: 50px;
    background-color: #ffffff;
    border: solid 1px #e9c169;
    padding: 15px 20px;
    font-size: 16px;
    width: 100%;
    max-width: 100%;
  }
  .responsive .login h1 {
    text-align: center;
    padding-top: 0px;
    font-size: 24px;
    margin-bottom: 14px;
  }
  .responsive .login fieldset #password,
  .responsive .login fieldset #username {
    margin: 0 0 14px;
  }
  .responsive .login fieldset label {
    display: block;
    padding: 0;
    font-size: 14px;
    font-style: italic;
    position: relative;
    margin-bottom: 5px;
  }
  .responsive .login fieldset #remember-lbl {
    display: inline;
  }
  .responsive .login .login-fields {
    margin: 0;
  }
  .responsive .login button[type="submit"] {
    margin-top: 24px;
    margin-bottom: 17px;
  }
  .responsive .login + div > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .responsive .login + div > ul li a {
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    text-align: center;
    color: #000000;
    text-decoration: underline;
  }
  .responsive .rsform-text-box {
    height: auto;
  }
  .responsive .rsform-captcha-box {
    width: 130px !important;
    margin-right: 89px;
  }
  .responsive .rsform-block-fz-catcha br {
    display: none;
  }
  .responsive .rsform-block-fz-catcha img {
    position: absolute;
    left: 146px;
    bottom: 11px;
  }
  .responsive .addthis_toolbox.addthis_default_style {
    display: none;
  }
  .responsive .rsform-block-fz-messaga .rsform-text-box {
    height: 140px;
  }
  .responsive .rsform-block-fz-submit-btn {
    padding-top: 13px;
  }
  .responsive .checkbox-block {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding-top: 10px;
  }
  .responsive .squaredFour {
    width: 26px;
    height: 26px;
    position: relative;
    margin-right: 10px;
  }
  .responsive .squaredFour label {
    width: 26px !important;
    height: 26px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
    border: solid 1px #e9c169;
  }
  .responsive .squaredFour label:after {
    content: '';
    width: 14px;
    height: 7px;
    position: absolute;
    top: 4px;
    left: 4px;
    border: 3px solid #333;
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 0;
    transform: rotate(-45deg);
  }
  .responsive .squaredFour input[type=checkbox] {
    visibility: hidden;
    position: absolute;
  }
  .responsive .squaredFour input[type=checkbox]:checked + label:after {
    opacity: 1;
  }
  .responsive .without-age {
    position: relative;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    margin-top: 18px;
  }
  .responsive .without-age .text {
    font-size: 14px;
    font-style: italic;
  }
  .responsive .reg-link {
    text-align: center;
    padding: 36px 0;
  }
  .responsive .reg-link a {
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    text-align: center;
    color: #000000;
    border-bottom: 1px dashed;
    text-decoration: none;
    text-transform: uppercase;
  }
  .responsive .catalog_page {
    overflow: initial;
  }
  .responsive .frofile-title {
    text-align: center;
    margin-bottom: 16px;
    font-size: 24px;
    padding-top: 4px;
  }
  .responsive .flex-align-items-center {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
  }
  .responsive h1.flex-align-items-center img {
    margin-right: 12px;
  }
  .responsive .new-wiskey-btn {
    margin: -30px 0 30px;
  }
  .responsive .catalog_title {
    text-align: center;
    font-size: 24px;
  }
  .responsive .moduletable_activitemodule select {
    display: inline-block;
    vertical-align: middle;
    border-radius: 10px !important;
    font-size: 22px;
    text-align: center;
    color: #ffffff;
    padding: 14px 18px;
    text-decoration: none;
    font-weight: 400;
    position: relative;
    max-width: 100%;
    background: linear-gradient(to right, rgba(180, 128, 66, 0.76) 0%, rgba(180, 128, 66, 0.76) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c2b48042', endColorstr='#c2b48042', GradientType=1);
    border: solid 2px #ffffff !important;
    display: block;
    width: 100% !important;
    font-family: 'PT Sans', sans-serif !important;
    margin-bottom: 18px;
    text-align: left;
  }
  .responsive .moduletable_activitemodule select option {
    background: rgba(180, 128, 66, 0.76);
    font-family: 'PT Sans', sans-serif !important;
  }
  .responsive #maincontent #maincontent {
    padding: 0 !important;
  }
  .responsive .filter-parameters .btn-submit {
    display: block;
    vertical-align: middle;
    border-radius: 10px;
    background: #000000;
    border: solid 2px #ffffff;
    font-size: 22px;
    text-align: center;
    color: #ffffff;
    padding: 14px 18px;
    text-decoration: none;
    font-weight: 400;
    position: relative;
    max-width: 100%;
    width: 100%;
    height: auto;
    margin: 0;
    font-family: 'PT Serif', sans-serif;
  }
  .responsive .steps-nav {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 30px;
  }
  .responsive .steps-nav:before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-image: linear-gradient(to right, #e9c169, #f7dfae);
    top: 23px;
    left: 0;
    position: absolute;
    z-index: -1;
  }
  .responsive .steps-nav li {
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #989184;
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
  }
  .responsive .steps-nav li span {
    width: 46px;
    height: 46px;
    background-color: #FFF8EA;
    border: solid 1px #e9c169;
    border-radius: 50%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'PT Serif', sans-serif;
    font-size: 21px;
    text-align: center;
    color: #BBB6AA;
    margin-bottom: 5px;
  }
  .responsive .steps-nav li.active {
    color: #000;
  }
  .responsive .steps-nav li.active span {
    background-color: #fff;
    color: #000;
  }
  .responsive .steps-nav li.done {
    color: #eac26c;
  }
  .responsive .steps-nav li.done span {
    background-color: #eac26c;
    color: #ffffff;
  }
  .responsive .bottle-add .page-header h2 {
    text-align: center;
    margin-bottom: 13px;
    font-size: 24px;
  }
  .responsive .bottle-add-container {
    margin: 0;
  }
  .responsive .bottle-add-container .form-horizontal label {
    width: auto;
  }
  .responsive .bottle-add-container .control-group {
    float: none;
    margin-bottom: 15px;
  }
  .responsive .bottle-add-container .control-group .red {
    color: #F00 !important;
  }
  .responsive .bottle-add-container .control-group .control-label {
    display: block;
    padding: 0;
    font-size: 14px;
    font-style: italic;
    position: relative;
    float: none;
    width: 100%;
    margin-bottom: 4px;
    text-align: left;
  }
  .responsive .bottle-add-container .control-group .controls {
    float: none;
    width: 100%;
  }
  .responsive .bottle-add-container .control-group .controls > div {
    float: none !important;
  }
  .responsive .bottle-add-container .control-group .controls select,
  .responsive .bottle-add-container .control-group .controls input {
    height: 50px;
    background-color: #ffffff !important;
    border: solid 1px #e9c169;
    padding: 15px 20px;
    font-size: 16px;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
  }
  .responsive .bottle-add-container .control-group .controls select[disabled="disabled"], .responsive .bottle-add-container .control-group .controls select[readonly="true"],
  .responsive .bottle-add-container .control-group .controls input[disabled="disabled"],
  .responsive .bottle-add-container .control-group .controls input[readonly="true"] {
    opacity: 0.5;
  }
  .responsive .bottle-add-container .control-group .controls select#strength, .responsive .bottle-add-container .control-group .controls select[name="bottle_size"], .responsive .bottle-add-container .control-group .controls select[name="number_of_bottles"], .responsive .bottle-add-container .control-group .controls select#bottled, .responsive .bottle-add-container .control-group .controls select#age, .responsive .bottle-add-container .control-group .controls select#vintage,
  .responsive .bottle-add-container .control-group .controls input#strength,
  .responsive .bottle-add-container .control-group .controls input[name="bottle_size"],
  .responsive .bottle-add-container .control-group .controls input[name="number_of_bottles"],
  .responsive .bottle-add-container .control-group .controls input#bottled,
  .responsive .bottle-add-container .control-group .controls input#age,
  .responsive .bottle-add-container .control-group .controls input#vintage {
    width: 64%;
  }
  .responsive .bottle-add-container .control-group .controls .input-comment {
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    color: #333333;
    display: block;
    margin-top: 3px;
    margin-left: 0 !important;
  }
  .responsive .bottle-add-container .bottle-add-step {
    display: none;
  }
  .responsive .bottle-add-container .bottle-add-step.show {
    display: block;
  }
  .responsive .bottle-add-container .bottle-add-step .btn, .responsive .bottle-add-container .bottle-add-step .rsform-submit-button, .responsive .bottle-add-container .bottle-add-step .login button[type="submit"], .responsive .login .bottle-add-container .bottle-add-step button[type="submit"], .responsive .bottle-add-container .bottle-add-step .fileContainer, .responsive .bottle-add-container .bottle-add-step.step-4 .form-actions button[type="submit"] {
    margin-top: 30px;
  }
  .responsive .bottle-add-container .bottle-add-step .checkbox-block {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    font-style: italic;
    padding-top: 36px;
  }
  .responsive .bottle-add-container .bottle-add-step .checkbox-block label {
    width: auto;
  }
  .responsive .bottle-add-container .bottle-add-step .checkbox-block .squaredFour label {
    width: 26px;
  }
  .responsive .bottle-add-container .bottle-add-step .fileContainer {
    overflow: hidden;
    position: relative;
    border: solid 2px #ffffff;
    font-family: 'PT Sans', sans-serif;
    background: linear-gradient(to right, rgba(180, 128, 66, 0.76) 0%, rgba(180, 128, 66, 0.76) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c2b48042', endColorstr='#c2b48042', GradientType=1);
    margin: 0;
  }
  .responsive .bottle-add-container .bottle-add-step .fileContainer [type=file] {
    cursor: inherit;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    z-index: 1;
  }
  .responsive .bottle-add-container .bottle-add-step.step-4 .input-comment {
    margin: 0 !important;
  }
  .responsive .bottle-add-container .bottle-add-step.step-4 .controls > div {
    float: none !important;
  }
  .responsive .bottle-add-container .bottle-add-step.step-4 > hr {
    display: none;
  }
  .responsive .bottle-add-container .bottle-add-step.step-4 .form-actions button[type="submit"] {
    margin: 0;
  }
  .responsive .distillery_image {
    max-width: 100%;
  }
  .responsive .distilleriesDescription {
    margin-top: 30px;
  }
  .responsive .distilleriesDescription hr {
    display: none;
  }
  .responsive .head_table_holder {
    width: 100%;
  }
  .responsive .center_subcol {
    width: 100%;
    margin-right: 0;
  }
  .responsive img {
    max-width: 100%;
  }
  .responsive .bottle_breadcrumbs ul li.arrow_box {
    padding: 4px 20px 4px 0px;
    position: relative;
    margin: 0 0 0 30px;
    font-size: 16px;
  }
  .responsive .bottle_breadcrumbs ul li.arrow_box:after {
    border-left-color: transparent;
    border-right-color: #e3c38a;
    left: -60px;
  }
  .responsive .bottle_info_block {
    position: relative;
  }
  .responsive .bottle_info_block h2 {
    text-align: center;
    margin-bottom: 13px;
    font-size: 24px;
    width: 100%;
  }
  .responsive .bottle_info_block .add-photo-whisky {
    position: absolute;
    right: 0;
    top: 84px;
    width: 60px;
    text-align: center;
  }
  .responsive .bottle_info_block .add-photo-whisky .fileContainer {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    background-image: url(../images/file-plus.svg), linear-gradient(to left, #a56b28, #b48042);
    background-position: center;
    background-repeat: no-repeat;
    border: solid 2px #ffffff;
    position: relative;
    overflow: hidden;
    display: block;
    margin-bottom: 6px;
  }
  .responsive .bottle_info_block .add-photo-whisky .fileContainer input[type="file"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .responsive .bottle_info_block .add-photo-whisky .text {
    font-family: 'PT Sans';
    font-size: 14px;
    color: #000000;
  }
  .responsive #maincontent_bottom_block > div[style="float: left;"] {
    float: none !important;
  }
  .responsive .moduletable_activitemodule img {
    margin-top: 0;
  }
  .responsive .add-photo-whisky {
    position: relative;
  }
  .responsive .table-bottle-info {
    margin-top: 25px;
    margin-bottom: 30px;
    width: 100%;
  }
  .responsive .description {
    min-width: 0;
  }
  .responsive .right {
    width: 100%;
  }
  .responsive .bottle-comments {
    width: 100%;
  }
  .responsive .bottle-comments h2 {
    font-family: 'PT Serif';
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    font-style: normal;
    text-transform: uppercase;
    margin: 18px 0 0px;
  }
  .responsive .bottle-comments .author img {
    width: 40px !important;
  }
  .responsive .bottle-comments .opinion-descr {
    font-size: 16px;
    line-height: 1.5;
    font-family: 'PT Serif';
    margin: 10px 0 !important;
  }
  .responsive .bottle-comments .opinion {
    display: none;
  }
  .responsive .bottle-comments .author_mark {
    font-family: 'PT Serif';
    font-size: 16px;
    font-weight: bold;
  }
  .responsive .bottle-comments .review {
    padding: 15px 0;
    border: 0;
  }
  .responsive .review-add-inform {
    margin: -38px 0 12px 0;
    border: solid 1px #f7c677;
    padding: 19px 20px 19px 20px;
    text-align: center;
    font-family: 'PT Serif';
    font-size: 16px;
  }
  .responsive .review-add-inform span {
    display: block;
  }
  .responsive .review-add-inform a {
    display: block;
  }
  .responsive .search {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 34;
    display: none;
  }
  .responsive .search .phrases,
  .responsive .search .only {
    display: none;
  }
  .responsive .search .word {
    border: 0;
    margin: 0;
    padding: 0 20px;
    height: 60px;
    background-color: #7e4509;
  }
  .responsive .search .word label {
    display: none;
  }
  .responsive .search .word input[type="text"] {
    border-radius: 10px;
    background-color: #5C3615;
    border: 0;
    outline: none;
    box-shadow: none;
    height: 46px;
    padding: 0 15px;
    color: #fff;
    font-size: 18px;
    width: calc(100% - 40px);
  }
  .responsive .search .word button.button {
    width: 25px;
    height: 25px;
    background: url(../images/icon_search.svg);
    background-size: 100% 100%;
    overflow: hidden;
    color: transparent;
    font-size: 0;
    padding: 0;
    position: relative;
    top: 6px;
    right: -11px;
  }
  .responsive .btns-wrapp {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
  }
  .responsive .btns-wrapp .btn, .responsive .btns-wrapp .rsform-submit-button, .responsive .btns-wrapp .login button[type="submit"], .responsive .login .btns-wrapp button[type="submit"], .responsive .btns-wrapp .bottle-add-container .bottle-add-step .fileContainer, .responsive .bottle-add-container .bottle-add-step .btns-wrapp .fileContainer, .responsive .btns-wrapp .bottle-add-container .bottle-add-step.step-4 .form-actions button[type="submit"], .responsive .bottle-add-container .bottle-add-step.step-4 .form-actions .btns-wrapp button[type="submit"] {
    width: calc(50% - 15px);
    font-size: 20px;
    font-family: 'PT Serif';
  }
  .responsive .btns-wrapp .btn.btn-descr, .responsive .btns-wrapp .btn-descr.rsform-submit-button, .responsive .btns-wrapp .login button.btn-descr[type="submit"], .responsive .login .btns-wrapp button.btn-descr[type="submit"], .responsive .btns-wrapp .bottle-add-container .bottle-add-step .btn-descr.fileContainer, .responsive .bottle-add-container .bottle-add-step .btns-wrapp .btn-descr.fileContainer, .responsive .btns-wrapp .bottle-add-container .bottle-add-step.step-4 .form-actions button.btn-descr[type="submit"], .responsive .bottle-add-container .bottle-add-step.step-4 .form-actions .btns-wrapp button.btn-descr[type="submit"] {
    background-image: linear-gradient(to left, #a56b28, #b48042);
  }
  .responsive .mobile-banner {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    top: 14px;
    left: 14px;
    right: 14px;
    bottom: 14px;
    background-size: cover;
    background-position: center;
    padding: 14px;
    z-index: 9;
  }
  .responsive .mobile-banner .content-banner {
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    font-family: 'PT Sans', sans-serif;
  }
  .responsive .mobile-banner .close-banner {
    top: 20px;
    right: 15px;
    position: absolute;
    display: block;
    width: 39px;
    height: 39px;
    background: url("../images/close.svg") center no-repeat;
    cursor: pointer;
  }
}
