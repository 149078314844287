@import url('https://fonts.googleapis.com/css?family=PT+Sans');

.footer-link, .visible-mobile, .mobile-banner {
    display: none;
}


html {
    height: 100%;
}

.mobileMenu {
    background: #7e4509;
    padding: 16px 20px 16px;
    margin: 0 -15px 14px;
    position: relative;
    a {
        text-decoration: none;
        color: #ffffff;
        font-size: 22px;
        font-family: 'PT Sans', sans-serif;
    }
    .icon-menu {
        margin-top: -5px;
        margin-right: 14px;
    }
    .icon-search {
        float: right;
        margin-right: 5px;
        &.active {
          background-image: url(../images/close.svg);
          & + .search {
            display: block;
          }
        }
    }
}

@media (min-width: 992px) {

    .hidden-lg {
        display: none !important;
    }
}

.responsive {
    @media (max-width: 991px) {
        background: #fff1d5;
        .footer_placeholder {
            height: 86px;
        }
        min-height: 100%;
        height: auto;
        position: relative;
        #podlozka {
            height: auto;
        }
        &.gradient-bg {
            background: radial-gradient(circle at 100% 100%, #fff1bf, #c7833b 56%, #8a480f);
            &.home {
                #maincontent {
                    background: transparent;
                    padding: 20px 15px;
                    > * {
                        display: none;
                    }
                    .mobileLinks {
                        display: block;
                    }
                }
            }
        }
        &.is-home {
            background: #000 url("../images/body_bg.jpg") center top no-repeat;
            background-size: cover;
            font-family: 'PT Sans', sans-serif;
            #maincontent {
                background: transparent;
                padding: 20px 15px;
                > * {
                    display: none;
                }
                .mobileLinks {
                    display: block;
                }
            }
        }
        * {
            box-sizing: border-box;
        }

        min-width: 320px;

        .visible-mobile {
            display: block !important;
        }
        .hidden-mobile {
            display: none !important;
        }

        #wrapper,
        #header,
        #maincontent_bottom_block,
        .center_subcol.wide,
        #maincontent,
        .right.wide,
        .moduletable_activitemodule,
        #footer,
        .left {
            width: 100%;
            float: none;
            margin: 0;
        }
        .catalog_title, .distilleriesDescription, .table-drinks, #SearchForm, .admin_text {
            width: 100%;
        }

        #maincontent {
            padding: 0 15px;
            background-color: transparent;
        }
        #header {
            background: #000000;
            height: auto;
            padding: 23px 0 24px;
            font-family: 'PT Sans', sans-serif;
            #logo {
                float: none;
                width: 100%;
                margin: 0;
                text-align: center;
            }
        }

        .moduletable_top_menu, .moduletable_breadcrumbs {
            display: none;
        }

        .right_subcol, .analyticstracking {
            position: absolute;
            left: -9999em;
            top: -9999em;
        }

        .right {
            background-image: none;
            float: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .moduletable_activitemodule {
            .row {

            }
            .col {
                width: 100% !important;
                display: block;
            }
        }

        #footer {
            font-family: 'PT Sans', sans-serif;
            height: auto;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            > * {
                display: none;
            }
            .moduletable_footer_copyright {
                font-family: 'PT Sans', sans-serif;
                background: #000000;
                padding: 13px 0;
                font-size: 14px;
                color: #ebebeb;
                float: none;
                display: block;
                text-align: center;
                margin: 0;
            }
            .footer-link {

                text-align: center;
                display: block;
                font-size: 16px;
                color: #000000;
                margin-bottom: 14px;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
                span {
                    display: inline-block;
                    line-height: 21px;
                    border-bottom: 1px dashed;
                }
            }
        }

        .icon {
            display: inline-block;
            vertical-align: middle;
            background-position: center center;
            background-repeat: no-repeat;
            &-user {
                width: 31px;
                height: 35px;
                background-image: url(../images/icon_user.svg);
            }
            &-cat {
                width: 31px;
                height: 35px;
                background-image: url(../images/icon_cat.svg);
            }
            &-forum {
                width: 31px;
                height: 35px;
                background-image: url(../images/icon_forum.svg);
            }
            &-cont {
                width: 31px;
                height: 35px;
                background-image: url(../images/icon_cont.svg);
            }
            &-menu {
                width: 26px;
                height: 21px;
                background-image: url(../images/icon_menu.svg);
            }
            &-search {
                width: 25px;
                height: 25px;
                background-image: url(../images/icon_search.svg);
                background-size: 100% 100%;
            }
            &-key {
                width: 26px;
                height: 26px;
                background-image: url(../images/icon-key.svg);
            }

        }

        .btn {
            display: inline-block;
            vertical-align: middle;
            border-radius: 10px;
            background-color: #000000;
            border: solid 2px #ffffff;
            font-size: 22px;
            text-align: center;
            color: #ffffff;
            padding: 14px 18px;
            text-decoration: none;
            font-weight: 400;
            position: relative;
            max-width: 100%;

            &-block {
                display: block;
                width: 100%;
            }
            &-trans {
                background: -moz-linear-gradient(left, rgba(180, 128, 66, 0.76) 0%, rgba(180, 128, 66, 0.76) 100%);
                background: -webkit-linear-gradient(left, rgba(180, 128, 66, 0.76) 0%, rgba(180, 128, 66, 0.76) 100%);
                background: linear-gradient(to right, rgba(180, 128, 66, 0.76) 0%, rgba(180, 128, 66, 0.76) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c2b48042', endColorstr='#c2b48042', GradientType=1);
                border: solid 2px #ffffff;
            }
            &-red {
                background-image: linear-gradient(to left, #bb3b3b, #d96249);
                font-family: 'PT Sans', serif;
            }
            &-icon {
                text-align: left;
                padding-left: 60px;
                .icon {
                    position: absolute;
                    left: 13px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .mobileLinks {
            margin-bottom: 11px;
            .btn {
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        //page styles
        .item-page {
            font-family: 'PT Serif', serif;
            margin-bottom: 32px;
            h1 {
                text-align: center;
                margin-bottom: 13px;
                font-size: 24px;
            }
            h4 {
                margin-top: 0;
                font-size: 16px;
                font-weight: bold;
                color: #000000;
                margin-bottom: 10px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
                margin: 0;
            }
        }
        .componentheading {
            text-align: center;
            padding-top: 20px;
            font-size: 24px;
            margin-bottom: 12px;
        }

        .rsform-submit-button {
            @extend .btn;
            @extend .btn-block;
        }

        #userForm {
            table, table tbody {
                width: 100%;
                display: block;
            }
            table {

                tr {
                    display: block;
                    margin-bottom: 14px;
                    td {
                        display: block;
                        padding: 0;
                        font-size: 14px;
                        font-style: italic;
                        position: relative;
                        &:first-child {
                            padding-bottom: 5px;
                        }
                    }
                }
            }
        }
        .login fieldset #password,
        .login fieldset #username,
        .rsform-input-box, .rsform-select-box, .rsform-captcha-box, .rsform-text-box {
            height: 50px;
            background-color: #ffffff;
            border: solid 1px #e9c169;
            padding: 15px 20px;
            font-size: 16px;
            width: 100%;
            max-width: 100%;
        }
        .login {
            h1 {
                text-align: center;
                padding-top: 0px;
                font-size: 24px;
                margin-bottom: 14px;
            }
            fieldset {
                #password,
                #username {
                    margin: 0 0 14px;
                }
                label {
                    display: block;
                    padding: 0;
                    font-size: 14px;
                    font-style: italic;
                    position: relative;
                    margin-bottom: 5px;
                }
                #remember-lbl {
                  display: inline;
                }

            }
            .login-fields {
                margin: 0;
            }
            button[type="submit"] {
                @extend .btn;
                @extend .btn-block;
                margin-top: 24px;
                margin-bottom: 17px;
            }
            & + div > ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                align-items: center;
                justify-content: space-between;
                li {
                    a {
                        font-family: 'PT Sans', sans-serif;
                        font-size: 16px;
                        text-align: center;
                        color: #000000;
                        text-decoration: underline;
                    }
                }
            }
        }

        .rsform-text-box {
            height: auto;
        }
        .rsform-captcha-box {
            width: 130px !important;
            margin-right: 89px;
        }
        .rsform-block-fz-catcha {
            br {
                display: none;
            }
            img {
                position: absolute;
                left: 146px;
                bottom: 11px;
            }
        }

        .addthis_toolbox.addthis_default_style {
            display: none;
        }

        .rsform-block-fz-messaga {
            .rsform-text-box {
                height: 140px;
            }
        }

        .rsform-block-fz-submit-btn {
            padding-top: 13px;
        }
        .checkbox-block {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: flex-end;
            padding-top: 10px;
        }
        .squaredFour {
            width: 26px;
            height: 26px;
            position: relative;
            margin-right: 10px;
            label {
                width: 26px !important;
                height: 26px;
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #ffffff;
                 border: solid 1px #e9c169;
                &:after {
                    content: '';
                    width: 14px;
                    height: 7px;
                    position: absolute;
                    top: 4px;
                    left: 4px;
                    border: 3px solid #333;
                    border-top: none;
                    border-right: none;
                    background: transparent;
                    opacity: 0;
                    transform: rotate(-45deg);
                }
            }
            input[type=checkbox] {
                visibility: hidden;
                position: absolute;
                &:checked + label:after {
                    opacity: 1;
                }
            }
        }
        .without-age {
          position: relative;
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          align-items: center;
          margin-top: 18px;
          .text {
            font-size: 14px;
            font-style: italic;
          }
        }
        .reg-link {
            text-align: center;
            padding: 36px 0;
            a {
                font-family: 'PT Sans', sans-serif;
                font-size: 16px;
                text-align: center;
                color: #000000;
                border-bottom: 1px dashed;
                text-decoration: none;
                text-transform: uppercase;
            }
        }
        .catalog_page {
            overflow: initial;
        }
        .frofile-title {
            text-align: center;
            margin-bottom: 16px;
            font-size: 24px;
            padding-top: 4px;
        }
        .flex-align-items-center {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
        }
        h1.flex-align-items-center {
            img {
                margin-right: 12px;
            }
        }
        .new-wiskey-btn {
            margin: -30px 0 30px;
        }
        .catalog_title {
            text-align: center;
            // margin-bottom: 13px;
            font-size: 24px;
        }
        .moduletable_activitemodule {
            select {
                display: inline-block;
                vertical-align: middle;
                border-radius: 10px !important;
                font-size: 22px;
                text-align: center;
                color: #ffffff;
                padding: 14px 18px;
                text-decoration: none;
                font-weight: 400;
                position: relative;
                max-width: 100%;
                background: linear-gradient(to right, rgba(180, 128, 66, 0.76) 0%, rgba(180, 128, 66, 0.76) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c2b48042', endColorstr='#c2b48042', GradientType=1);
                border: solid 2px #ffffff !important;
                display: block;
                width: 100% !important;
                font-family: 'PT Sans', sans-serif !important;
                margin-bottom: 18px;
                text-align: left;
                option {
                    background: rgba(180, 128, 66, 0.76);
                    font-family: 'PT Sans', sans-serif !important;
                }
            }
        }
        #maincontent #maincontent {
            padding: 0 !important;
        }
        .filter-parameters {
            .btn-submit {
                display: block;
                vertical-align: middle;
                border-radius: 10px;
                background: #000000;
                border: solid 2px #ffffff;
                font-size: 22px;
                text-align: center;
                color: #ffffff;
                padding: 14px 18px;
                text-decoration: none;
                font-weight: 400;
                position: relative;
                max-width: 100%;
                width: 100%;
                height: auto;
                margin: 0;
                font-family: 'PT Serif', sans-serif;
            }
        }
        .steps-nav {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;
            align-items: center;
            justify-content: space-between;
            position: relative;
            margin-bottom: 30px;
            &:before {
                content: '';
                display: block;
                height: 1px;
                width: 100%;
                background-image: linear-gradient(to right, #e9c169, #f7dfae);
                top: 23px;
                left: 0;
                position: absolute;
                z-index: -1;
            }
            li {
                margin: 0;
                padding: 0;
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                align-items: center;
                flex-direction: column;
                color: #989184;
                font-family: 'PT Sans', sans-serif;
                font-size: 14px;
                span {
                    width: 46px;
                    height: 46px;
                    background-color: #FFF8EA;
                    border: solid 1px #e9c169;
                    border-radius: 50%;
                    display: -webkit-flex;
                    display: -ms-flex;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: 'PT Serif', sans-serif;
                    font-size: 21px;
                    text-align: center;
                    color: #BBB6AA;
                    margin-bottom: 5px;
                }
                &.active {
                    color: #000;
                    span {
                        background-color: #fff;
                        color: #000;
                    }
                }
                &.done {
                    color: #eac26c;
                    span {
                        background-color: #eac26c;
                        color: #ffffff;
                    }
                }
            }
        }
        .bottle-add {
            .page-header {
                h2 {
                    text-align: center;
                    margin-bottom: 13px;
                    font-size: 24px;
                }
            }
        }
        .bottle-add-container {
            margin: 0;
            .form-horizontal label {
                width: auto;
            }
            .control-group {
                float: none;
                margin-bottom: 15px;
                .red {
                    color: #F00!important;
                }
                .control-label {
                    display: block;
                    padding: 0;
                    font-size: 14px;
                    font-style: italic;
                    position: relative;
                    float: none;
                    width: 100%;
                    margin-bottom: 4px;
                    text-align: left;
                }
                .controls {
                    float: none;
                    width: 100%;
                    & > div {
                      float: none !important;
                    }
                    select,
                    input {
                        height: 50px;
                        background-color: #ffffff !important;
                        border: solid 1px #e9c169;
                        padding: 15px 20px;
                        font-size: 16px;
                        width: 100%;
                        max-width: 100%;
                        border-radius: 0;
                        &[disabled="disabled"],
                        &[readonly="true"] {
                            opacity: 0.5;
                        }
                        &#strength,
                        &[name="bottle_size"],
                        &[name="number_of_bottles"],
                        &#bottled,
                        &#age,
                        &#vintage {
                            width: 64%;
                        }
                    }
                    .input-comment {
                        font-family: 'PT Sans', sans-serif;
                        font-size: 14px;
                        text-align: left;
                        color: #333333;
                        display: block;
                        margin-top: 3px;
                        margin-left: 0 !important;
                    }
                }
            }
            .bottle-add-step {
                display: none;
                &.show {
                    display: block;
                }
                .btn {
                    margin-top: 30px;
                }
                .checkbox-block {
                    display: -webkit-flex;
                    display: -ms-flex;
                    display: flex;
                    align-items: center;
                    font-style: italic;
                    padding-top: 36px;
                    label {
                        width: auto;
                    }
                    .squaredFour label {
                        width: 26px;
                    }
                }
                .fileContainer {
                    overflow: hidden;
                    position: relative;
                    @extend .btn;
                    @extend .btn-block;
                    border: solid 2px #ffffff;
                    font-family: 'PT Sans', sans-serif;
                    background: linear-gradient(to right, rgba(180, 128, 66, 0.76) 0%, rgba(180, 128, 66, 0.76) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c2b48042', endColorstr='#c2b48042', GradientType=1);
                    margin: 0;
                    [type=file] {
                        cursor: inherit;
                        display: block;
                        font-size: 999px;
                        filter: alpha(opacity=0);
                        min-height: 100%;
                        min-width: 100%;
                        opacity: 0;
                        position: absolute;
                        right: 0;
                        text-align: right;
                        top: 0;
                        z-index: 1;
                    }
                }
                &.step-4 {
                    .input-comment {
                      margin: 0 !important;
                    }
                    .controls > div {
                        float: none !important;
                    }
                    & > hr {
                      display: none;
                    }
                    .form-actions button[type="submit"]{
                      @extend .btn;
                      @extend .btn-block;
                      margin: 0;
                    }
                }
            }
        }
        .distillery_image {
            max-width: 100%;
        }
        .distilleriesDescription {
            margin-top: 30px;
            hr {
                display: none;
            }
        }
        .head_table_holder {
            width: 100%;
        }
        .center_subcol {
            width: 100%;
            margin-right: 0;
        }
        img {
            max-width: 100%;
        }
        .bottle_breadcrumbs ul li.arrow_box {
            padding: 4px 20px 4px 0px;
            position: relative;
            margin: 0 0 0 30px;
            font-size: 16px;
            &:after {
                border-left-color: transparent;
                border-right-color: #e3c38a;
                left: -60px;
            }
        }
        .bottle_info_block {
            position: relative;
            h2 {
                text-align: center;
                margin-bottom: 13px;
                font-size: 24px;
                width: 100%;
            }
            .add-photo-whisky {
                position: absolute;
                right: 0;
                top: 84px;
                width: 60px;
                text-align: center;
                .fileContainer {
                    width: 60px;
                    height: 60px;
                    border-radius: 10px;
                    background-image: url(../images/file-plus.svg), linear-gradient(to left, #a56b28, #b48042);
                    background-position: center;
                    background-repeat: no-repeat;
                    border: solid 2px #ffffff;
                    position: relative;
                    overflow: hidden;
                    display: block;
                    margin-bottom: 6px;
                    input[type="file"] {
                        position: absolute;
                        opacity: 0;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                    }
                }
                .text {
                    font-family: 'PT Sans';
                    font-size: 14px;
                    color: #000000;
                }
            }
        }
        #maincontent_bottom_block > div[style="float: left;"] {
            float: none !important;
        }
        .moduletable_activitemodule img {
            margin-top: 0;
        }
        .add-photo-whisky {
            position: relative;
        }
        .table-bottle-info {
            margin-top: 25px;
            margin-bottom: 30px;
            width: 100%;
        }
        .description {
              min-width: 0;
        }
        .right {
            width: 100%;
        }
        .bottle-comments {
            width: 100%;
            h2 {
                font-family: 'PT Serif';
                font-size: 16px;
                font-weight: bold;
                text-align: left;
                font-style: normal;
                text-transform: uppercase;
                margin: 18px 0 0px;
            }
            .author {
                img {
                    width: 40px !important;
                }
            }
            .opinion-descr {
                font-size: 16px;
                line-height: 1.5;
                font-family: 'PT Serif';
                margin: 10px 0 !important;
            }
            .opinion {
                display: none;
            }
            .author_mark {
                font-family: 'PT Serif';
                font-size: 16px;
                font-weight: bold;
            }
            .review {
                padding: 15px 0;
                border: 0;
            }
        }
        .review-add-inform {
            margin: -38px 0 12px 0;
            border: solid 1px #f7c677;
            padding: 19px 20px 19px 20px;
            text-align: center;
            font-family: 'PT Serif';
            font-size: 16px;
            span {
              display: block;
            }
            a {display: block;}
        }
        .search {
            position: absolute;
            top: 60px;
            left: 0;
            right: 0;
            z-index: 34;
            display: none;
            .phrases,
            .only {
                display: none;
            }
            .word {
                border: 0;
                margin: 0;
                padding: 0 20px;
                height: 60px;
                background-color: #7e4509;
                label {
                    display: none;
                }
                input[type="text"] {
                      border-radius: 10px;
                      background-color: #5C3615;
                      border: 0;
                      outline: none;
                      box-shadow: none;
                      height: 46px;
                      padding: 0 15px;
                      color: #fff;
                      font-size: 18px;
                      width: calc(100% - 40px);
                }
                button.button {
                    width: 25px;
                    height: 25px;
                    background: url(../images/icon_search.svg);
                    background-size: 100% 100%;
                    overflow: hidden;
                    color: transparent;
                    font-size: 0;
                    padding: 0;
                    position: relative;
                    top: 6px;
                    right: -11px;
                }
            }
        }
        .btns-wrapp {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 35px;
            .btn {
                width: calc(50% - 15px);
                font-size: 20px;
                font-family: 'PT Serif';
                &.btn-descr {
                    background-image: linear-gradient(to left, #a56b28, #b48042);
                }
            }
        }
        .mobile-banner {
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: fixed;
          top: 14px;
          left: 14px;
          right: 14px;
          bottom: 14px;
          background-size: cover;
          background-position: center;
          padding: 14px;
          z-index: 9;
          .content-banner {
            font-size: 36px;
            font-weight: bold;
            color: #ffffff;
            font-family: 'PT Sans', sans-serif;
          }
          .close-banner {
            top: 20px;
            right: 15px;
            position: absolute;
            display: block;
            width: 39px;
            height: 39px;
            background: url("../images/close.svg") center no-repeat;
            cursor: pointer;
          }
        }
    }
}
